import React, { useState } from 'react'
import {
	CenteredSection,
	DynamicPageSection,
	DynamicPageSectionRow,
	LazyLoad,
	LeftAlignedFlexBox,
	PageSidePaddingWrapper,
	XSmallPageSection,
} from '../../layout/PageComponents'
import { theme } from '../../../../utils/theme'
import { P1, P4 } from '../../Typography'
import ConditionalLink from '../ConditionalLink'
import Button from '../../buttons/Button'
import ResponsiveImage from '../../responsive/ResponsiveImage'
import parseDangerousHTML from '../../../../utils/parseDangerousHTML'
import { replaceGoldTags } from './SaFrontendComponent'
import styled from 'styled-components'

const HalfTextHalfImage = ({ frontendComponent, t }) => {
	const { title, headline, description, link, linkText, secondaryLink, secondaryLinkText, image, imageType, inverted } =
		frontendComponent
	return (
		<PageSidePaddingWrapper>
			<DynamicPageSectionRow
				reversedOnDesktop={inverted}
				margins={{
					outer: theme.margins.XXLargeInt,
					innerHorizontalDesktop: theme.margins.largeInt,
					innerMobile: theme.margins.largeInt,
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<CenteredSection onlyCenterOnMobile>
						{parseDangerousHTML(replaceGoldTags(title), <P1 />)}
						{headline && (
							<>
								<XSmallPageSection />
								<P4 textColor="lightTextColor">{headline}</P4>
							</>
						)}
						<DynamicPageSection
							margins={{ topMobile: theme.margins.smallInt, topDesktop: theme.margins.largeInt, bottom: 0 }}
						>
							<p>{description}</p>
						</DynamicPageSection>
					</CenteredSection>
					{(link || secondaryLink) && (
						<DynamicPageSection margins={{ topMobile: theme.margins.smallInt, topDesktop: theme.margins.largeInt }}>
							<CenteredSection onlyCenterOnMobile>
								<LeftAlignedFlexBox>
									{link && (
										<ConditionalLink to={link}>
											<Button>{linkText || t('common:buttons.readmore')}</Button>
										</ConditionalLink>
									)}
									{secondaryLink && (
										<ConditionalLink to={secondaryLink}>
											<Button color="OUTLINED_BLACK">{secondaryLinkText || t('common:buttons.readmore')}</Button>
										</ConditionalLink>
									)}
								</LeftAlignedFlexBox>
							</CenteredSection>
						</DynamicPageSection>
					)}
				</div>
				<div>
					<ScaleInImage>
						<ResponsiveImage
							imagePath={image}
							type={imageType === 'horizontal_rectangle' ? 'BIG_RECTANGLE' : 'VERTICAL_RECTANGLE'}
							alt={title}
						/>
					</ScaleInImage>
				</div>
			</DynamicPageSectionRow>
		</PageSidePaddingWrapper>
	)
}

const StyledScaleInImage = styled.div`
	overflow: hidden;
	> div {
		opacity: ${({ loaded }) => (loaded ? 1 : 0)};
		scale: ${({ loaded }) => (loaded ? 1 : 1.1)};
		transition:
			opacity 2s,
			scale 2s;
	}
`
// Scales and fades the image in when scrolled to
const ScaleInImage = ({ children }) => {
	const [loaded, setLoaded] = useState(false)
	return (
		<LazyLoad>
			<StyledScaleInImage loaded={loaded}>
				{React.cloneElement(children, { ...children.props, onLoad: () => setLoaded(true) })}
			</StyledScaleInImage>
		</LazyLoad>
	)
}

export default HalfTextHalfImage
